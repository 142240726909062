// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  max-width: 350px;
}
.login .login-content {
  width: 100%;
}
.login .nav-tabs .nav-item {
  width: 50%;
}
.login .nav-tabs .nav-item button {
  width: 100%;
}
.login .tab-content {
  padding: 1rem;
}
.login .tab-content > [role='tabpanel'] {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Login/styles.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAFA;EAGI,WAAA;AAEJ;AALA;EAOK,UAAA;AACL;AARA;EASO,WAAA;AAEP;AAXA;EAcI,aAAA;AAAJ;AACI;EACE,aAAA;EACA,sBAAA;AACN","sourcesContent":[".login {\n  max-width: 350px;\n  .login-content {\n    width: 100%;\n  }\n .nav-tabs {\n   .nav-item {\n     width: 50%;\n     button {\n       width: 100%;\n     }\n   }\n }\n  .tab-content {\n    padding: 1rem;\n    &>[role='tabpanel'] {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
