// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile .user-info .input-container {
  width: 200px;
}
.profile .avatar {
  width: 128px;
  height: 128px;
  cursor: pointer;
  overflow: hidden;
}
.profile .avatar img {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Profile/styles.less"],"names":[],"mappings":"AAAA;EAGM,YAAA;AADN;AAFA;EAOI,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AARA;EAYM,WAAA;EACA,YAAA;AADN","sourcesContent":[".profile {\n  .user-info {\n    .input-container {\n      width: 200px;\n    }\n  }\n  .avatar {\n    width: 128px;\n    height: 128px;\n    cursor: pointer;\n    overflow: hidden;\n    img {\n      width: 100%;\n      height: auto;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
